import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { pages } from '../Data/Data';


function Navbar() {

    const [isOpen, setIsOpen] = useState(false);
    const url = `${window.location.href}`
    const path = url.split('/')[url.split('/').length - 1]
    const [active, setActive] = useState(path === "" ? "home" : path)

    // useEffect(() => {
    //     console.log(active);
    // }, [active])

    return (

        <nav className="flex z-10 items-center justify-between flex-wrap p-6">
            <div className="block lg:hidden sm:text-end">
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex items-center px-3 py-2 rounded text-end text-black-500 hover:text-black-400"
                >
                    <svg
                        className={`fill-current h-3 w-3 ${isOpen ? "hidden" : "block"}`}
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                    </svg>
                    <svg
                        className={`fill-current h-3 w-3 ${isOpen ? "block" : "hidden"}`}
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
                    </svg>
                </button>
            </div>
            <div className={`w-full block flex-grow justify-center gap-8 font-Poppins text-xl text-center lg:flex lg:items-center lg:w-auto  ${isOpen ? "block" : "hidden"}`}>
                {
                    pages.map((page, key) =>
                        <div key={key}>
                            <NavLink to={page.link} onClick={() => setActive(page.id)} className={active === page.id ? "font-bold underline underline-offset-4" : ""}>{page.title}</NavLink>
                        </div>
                    )
                }
            </div>
        </nav>

    );
}
export default Navbar;