// About Us
import AboutUsImage from '../Images/AboutUs/1.png'
import AboutUsLazyImage from '../Images/AboutUs/1-Lazy.jpg'
import WhoWeAre from '../Images/AboutUs/2.png'
import WhoWeAreLazyImage from '../Images/AboutUs/2-Lazy.jpg'
import WhatWeDo from '../Images/AboutUs/3.png'
import WhatWeDoLazyImage from '../Images/AboutUs/3-Lazy.jpg'
import OurMissionAndVision from '../Images/AboutUs/4.png'
import OurMissionAndVisionLazyImage from '../Images/AboutUs/4-Lazy.jpg'

import AboutUsEcoSystemImage1 from '../Images/AboutUs/Ecosystem/1.png'
import AboutUsEcoSystemLazyImage1 from '../Images/AboutUs/Ecosystem/1-Lazy.jpg'
import AboutUsEcoSystemImage2 from '../Images/AboutUs/Ecosystem/2.png'
import AboutUsEcoSystemLazyImage2 from '../Images/AboutUs/Ecosystem/2-Lazy.jpg'
// import AboutUsEcoSystemImage3 from '../Images/AboutUs/Ecosystem/3.png'
import AboutUsEcoSystemImage4 from '../Images/AboutUs/Ecosystem/4.png'
import AboutUsEcoSystemLazyImage4 from '../Images/AboutUs/Ecosystem/4-Lazy.jpg'
import AboutUsEcoSystemImage5 from '../Images/AboutUs/Ecosystem/5.png'
import AboutUsEcoSystemLazyImage5 from '../Images/AboutUs/Ecosystem/5-Lazy.jpg'
import AboutUsEcoSystemImage6 from '../Images/AboutUs/Ecosystem/6.png'
import AboutUsEcoSystemLazyImage6 from '../Images/AboutUs/Ecosystem/6-Lazy.jpg'
import AboutUsEcoSystemImage7 from '../Images/AboutUs/Ecosystem/7.png'
import AboutUsEcoSystemLazyImage7 from '../Images/AboutUs/Ecosystem/7-Lazy.jpg'
import AboutUsEcoSystemImage8 from '../Images/AboutUs/Ecosystem/8.png'
import AboutUsEcoSystemLazyImage8 from '../Images/AboutUs/Ecosystem/8-Lazy.jpg'



// Products
import ProductsMainImage from '../Images/Products/ProductsMainImage.png'
import ProductsMainLazyImage from '../Images/Products/ProductsMainLazyImage.jpg'
import CounterDroneSystemsImage from '../Images/Products/CounterDroneSystemsImage.png'
import CounterDroneSystemsLazyImage from '../Images/Products/CounterDroneSystemsLazyImage.jpg'
import DroneProductsImage from '../Images/Products/DroneProductsImage.png'
import DroneProductsLazyImage from '../Images/Products/DroneProductsLazyImage.jpg'
import TetheredDroneSystemsImage from '../Images/Products/TetheredDroneSystemsImage.jpg'
import TetheredDroneSystemsLazyImage from '../Images/Products/TetheredDroneSystemsLazyImage.jpg'
import AutonomousLeggedRobotsImage from '../Images/Products/AutonomousLeggedRobotsImage.png'
import AutonomousLeggedRobotsLazyImage from '../Images/Products/AutonomousLeggedRobotsLazyImage.jpg'
import AutonomousWheeledRobotsImage from '../Images/Products/AutonomousWheeledRobotsImage.png'
import AutonomousWheeledRobotsLazyImage from '../Images/Products/AutonomousWheeledRobotsLazyImage.jpg'

// Services
import ServicesSubImage1 from '../Images/Services/1.png'
import ServicesSubLazyImage1 from '../Images/Services/1-Lazy.jpg'
import ServicesSubImage2 from '../Images/Services/2.png'
import ServicesSubLazyImage2 from '../Images/Services/2-Lazy.jpg'
import ServicesSubImage3 from '../Images/Services/3.png'
import ServicesSubLazyImage3 from '../Images/Services/3-Lazy.jpg'
import ServicesSubImage4 from '../Images/Services/4.png'
import ServicesSubLazyImage4 from '../Images/Services/4-Lazy.jpg'
import ServicesAI from '../Images/Services/Services/AI.png'
import ServicesAILazy from '../Images/Services/Services/AI-Lazy.jpg'
import ServicesCV from '../Images/Services/Services/CV.jpg'
import ServicesCVLazy from '../Images/Services/Services/CV-Lazy.jpg'
import ServicesNLP from '../Images/Services/Services/NLP.jpg'
import ServicesNLPLazy from '../Images/Services/Services/NLP-Lazy.jpg'
import ServicesRH from '../Images/Services/Services/ROBOTICS HARDWARE.jpg'
import ServicesRHLazy from '../Images/Services/Services/ROBOTICS HARDWARE-Lazy.jpg'
import ServicesRS from '../Images/Services/Services/ROBOTICS SOFTWARE.png'
import ServicesRSLazy from '../Images/Services/Services/ROBOTICS SOFTWARE-Lazy.jpg'
import ServicesES from '../Images/Services/Services/EMBEDDED.jpg'
import ServicesESLazy from '../Images/Services/Services/EMBEDDED-Lazy.jpg'
import ServicesIOT from '../Images/Services/Services/IOT.png'
import ServicesIOTLazy from '../Images/Services/Services/IOT-Lazy.jpg'
import ServicesPCB from '../Images/Services/Services/PCB.png'
import ServicesPCBLazy from '../Images/Services/Services/PCB-Lazy.jpg'
import ServicesSD from '../Images/Services/Services/Software-Development.jpg'
import ServicesSDLazy from '../Images/Services/Services/Software-Development-Lazy.jpg'


import CarouselsImage1 from '../Images/Services/Carousels/1.jpg'
import CarouselsLazyImage1 from '../Images/Services/Carousels/1-Lazy.jpg'
import CarouselsImage2 from '../Images/Services/Carousels/2.png'
import CarouselsLazyImage2 from '../Images/Services/Carousels/2-Lazy.jpg'
import CarouselsImage3 from '../Images/Services/Carousels/3.jpg'
import CarouselsLazyImage3 from '../Images/Services/Carousels/3-Lazy.jpg'


import CustomersImage1 from '../Images/Services/Customers/1.png'
import CustomersLazyImage1 from '../Images/Services/Customers/1-Lazy.jpg'
import CustomersImage2 from '../Images/Services/Customers/2.png'
import CustomersLazyImage2 from '../Images/Services/Customers/2-Lazy.jpg'
import CustomersImage3 from '../Images/Services/Customers/3.png'
import CustomersLazyImage3 from '../Images/Services/Customers/3-Lazy.jpg'
import CustomersImage4 from '../Images/Services/Customers/4.png'
import CustomersLazyImage4 from '../Images/Services/Customers/4-Lazy.jpg'
import CustomersImage5 from '../Images/Services/Customers/5.png'
import CustomersLazyImage5 from '../Images/Services/Customers/5-Lazy.jpg'
import CustomersImage6 from '../Images/Services/Customers/6.png'
import CustomersLazyImage6 from '../Images/Services/Customers/6-Lazy.jpg'


// Careers
// import CareersImage from '../Images/Careers/1.png'

// Contract
import ContactImage from '../Images/Contact/1.png'
import ContactLazyImage from '../Images/Contact/1-Lazy.jpg'


export const pages = [
    {
        id: "home",
        title: "Home",
        link: "/"
    },
    {
        id: "aboutus",
        title: "About Us",
        link: "/aboutus"
    },
    {
        id: "products",
        title: "Products",
        link: "/products"
    },
    // {
    //     id: "services",
    //     title: "Services",
    //     link: "/services"
    // },
    {
        id: "careers",
        title: "Careers",
        link: "/careers"
    },
    // {
    //     id: "blog",
    //     title: "Blog",
    //     link: "/blog"
    // },
    {
        id: "contact",
        title: "Contact",
        link: "/contact"
    }
]

export const HomePageContent = {
    title: "Zebu Intelligent Systems",
    tagLine: "There’s a better way to do it!",
    subtagLine: "Keeping it simple since the beginning"
}

export const AboutUsImages = {
    images: [
        {
            image: AboutUsImage
        },
        {
            image: WhoWeAre
        },
        {
            image: WhatWeDo
        },
        {
            image: OurMissionAndVision
        },
    ],
    lazyImages: [
        {
            image: AboutUsLazyImage
        },
        {
            image: WhoWeAreLazyImage
        },
        {
            image: WhatWeDoLazyImage
        },
        {
            image: OurMissionAndVisionLazyImage
        },
    ],
    ecoSystem: [
        AboutUsEcoSystemImage1,
        AboutUsEcoSystemImage4,
        AboutUsEcoSystemImage2,
        AboutUsEcoSystemImage7,
        // AboutUsEcoSystemImage3,
        AboutUsEcoSystemImage5,
        AboutUsEcoSystemImage8,
        AboutUsEcoSystemImage6,
    ],
    ecoSystemLazy: [
        AboutUsEcoSystemLazyImage1,
        AboutUsEcoSystemLazyImage4,
        AboutUsEcoSystemLazyImage2,
        AboutUsEcoSystemLazyImage7,
        // AboutUsEcoSystemImage3,
        AboutUsEcoSystemLazyImage5,
        AboutUsEcoSystemLazyImage8,
        AboutUsEcoSystemLazyImage6,
    ]
}

export const ProductsContent = {
    mainPage:{
        content1: "Discover the future with our dual-use AI and Robotics solutions. Seamlessly adaptable to diverse applications, our products embody innovation that revolutionizes industries.",
        content2: "Get in touch for product specifications.",
        image: ProductsMainImage,
        lazyImage: ProductsMainLazyImage
    },
    droneProducts:{
        title: "Drone Products",
        content: "Our drone products are at the forefront of technological innovation, excelling in both civil and defense applications with a unique emphasis on AI-powered swarming capabilities. In civil sectors, they redefine efficiency in agriculture, photography, and inspections, leveraging AI for precise data analysis and autonomous flight. In defense, our drones with AI-driven swarming technology deliver advanced surveillance, reconnaissance, and tactical prowess, enabling coordinated, synchronized operations that minimize risks to personnel. This game-changing capability enhances national security by optimizing response times and resource allocation during missions, solidifying our position as a leader in the drone industry.",
        image: DroneProductsImage,
        lazyImage: DroneProductsLazyImage
    },
    tetheredDroneSystems:{
        title: "Tethered Drone System",
        content: "Zebu Intelligent Systems' tethered drone package offers a comprehensive solution for prolonged surveillance of aerial missions. Customization of the drone camera payload is possible to suit specific applications. Effortless operation, along with simplified altitude adjustment, takeoff, and landing, is achieved through a user-friendly interface on the ground control station. The ZEBUIS-GTS5000 ground tether station ensures uninterrupted power through a 100m tether, supporting the drone's input power. The length of the tether can be modified according to specific needs, allowing for either a reduction or an increase. Significantly, should a power outage occur, the system integrates an automatic landing feature, utilizing the redundant battery to ensure a secure descent. This enhances safety and reliability during prolonged operations.",
        image: TetheredDroneSystemsImage,
        lazyImage: TetheredDroneSystemsLazyImage
    },
    counterDroneSystems:{
        title: "Counter-Drone Systems",
        content: "Our cutting-edge counter-drone systems are versatile solutions designed for dual-use scenarios, securing both civil and defense interests. With an AI-driven approach, they detect and neutralize unauthorized drone intrusions with exceptional precision, ensuring the safety of critical infrastructure in civil settings and countering hostile drone activities in defense. Our systems effectively handle swarming threats, exemplifying their advanced capabilities in adapting to evolving airborne challenges. From securing public events to safeguarding military installations, our counter-drone systems equipped with AI technology are the ultimate defense against modern aerial threats.",
        image: CounterDroneSystemsImage,
        lazyImage: CounterDroneSystemsLazyImage
    },
    autonomousWheeledRobots:{
        title: "Autonomous Wheeled Robots",
        content: "Autonomous wheeled robots are versatile innovations that find dual-use applications across both civil and defense sectors. In civil applications, they are at the forefront of warehouse automation, logistics optimization, and manufacturing processes. These robots efficiently transport goods, manage inventory, and navigate dynamic environments, enhancing productivity and reducing operational costs. Furthermore, they play a crucial role in healthcare, providing assistance and support in tasks such as medication delivery and patient care, relieving healthcare professionals and improving overall patient experience. Their adaptability and ability to navigate diverse terrains make them invaluable tools for addressing various civilian needs.",
        image: AutonomousWheeledRobotsImage,
        lazyImage: AutonomousWheeledRobotsLazyImage
        
    },
    autonomousLeggedRobots:{
        title: "Autonomous Legged Robots",
        content: "Legged robots represent a remarkable technological advancement with dual-use potential in civil and defense domains. In civil applications, they are poised to revolutionize search and rescue operations in challenging terrains, assist in hazardous environments, and even enhance mobility for individuals with disabilities. Their unique ability to traverse rough terrain, climb obstacles, and maintain stability in unpredictable conditions makes them ideal for addressing complex civilian challenges. In the defense sector, legged robots play a crucial role in reconnaissance, surveillance, and logistics support. Their agility and versatility enable them to navigate uneven landscapes and provide real-time situational awareness, reducing risks to military personnel and enhancing mission effectiveness. The dual-use nature of legged robots highlights their significant contributions to addressing diverse challenges and advancing technology in both civil and defense applications.",
        image: AutonomousLeggedRobotsImage,
        lazyImage: AutonomousLeggedRobotsLazyImage

    },
}

export const ServicesContent = {
    head: {
        title: "Why work with us?",
        content: "Empowering Industries through Cutting-Edge AI Innovation. We deliver transformative solutions that drive efficiency, growth, and success across diverse sectors."
    },

    boxes: [
        {
            icon: ServicesSubImage1,
            lazyIcon: ServicesSubLazyImage1,
            title: "Expertise and Innovation",
            content: "Benefit from our founder's visionary leadership, enriched by a PhD in Intelligent Systems and Robotics, combined with practical experience at Tesla. Our team pioneers innovation that merge academic excellence with real-world insights."
        },
        {
            icon: ServicesSubImage2,
            lazyIcon: ServicesSubLazyImage2,
            title: "Industry 4.0 Focus",
            content: "Stay ahead in the Industry 4.0 landscape with our specialized AI and Robotics solutions. Tailored for defense and transportation sectors, our offerings empower clients to embrace automation and intelligence for sustained success."
        },
        {
            icon: ServicesSubImage3,
            lazyIcon: ServicesSubLazyImage3,
            title: "Proven Track Record",
            content: "Leverage our founder's successful IoT startup exit and entrepreneurial acumen. We bring results-driven dedication to every project, translating ideas into impactful solutions that drive growth and efficiency."
        },
        {
            icon: ServicesSubImage4,
            lazyIcon: ServicesSubLazyImage4,
            title: "Holistic Approach",
            content: "Experience seamless end-to-end solutions. From research and development to implementation, we ensure a comprehensive approach that integrates smoothly, delivering maximum value across your operations."
        }
    ],

    OurServices: [
        {
            title: "Artificial Intelligence",
            description: "Our AI services harness the incredible potential of artificial intelligence to transform businesses across various industries.",
            content: "Our AI services harness the incredible potential of artificial intelligence to transform businesses across various industries. Leveraging advanced machine learning and deep learning algorithms, we craft intelligent solutions that enable predictive analytics, process automation, and data-driven decision-making. Whether it's creating chatbots for enhanced customer support, developing recommendation systems for e-commerce platforms, or implementing predictive maintenance solutions for industrial equipment, our AI expertise empowers organizations to gain a competitive edge through improved efficiency, insights, and customer engagement.",
            image: ServicesAI,
            lazyImage: ServicesAILazy
        },
        {
            title: "Computer Vision",
            description: "In the domain of Computer Vision, our capabilities unlock a myriad of opportunities for visual data analysis.",
            content: "In the domain of Computer Vision, our capabilities unlock a myriad of opportunities for visual data analysis. We specialize in the development of applications that can comprehend and interpret images and videos. This includes cutting-edge technologies such as facial recognition, object detection, and quality control in manufacturing. By harnessing the power of Computer Vision, businesses can automate tasks, bolster security measures, and extract invaluable insights from visual data, paving the way for enhanced operational efficiency and innovation in a rapidly evolving digital landscape.",
            image: ServicesCV,
            lazyImage: ServicesCVLazy
        },
        {
            title: "Natural Language Processing",
            description: "Natural Language Processing (NLP) forms the foundation of numerous modern applications, and our expertise in this field is unparalleled.",
            content: "Natural Language Processing (NLP) forms the foundation of numerous modern applications, and our expertise in this field is unparalleled. We craft NLP solutions that empower businesses with sentiment analysis, chatbots for seamless customer interactions, multilingual language translation, and more. Our NLP applications extract nuanced meaning from textual and spoken language, enabling organizations to improve customer service, gain actionable insights from user-generated content, and automate routine communication tasks, thereby enhancing productivity and user satisfaction.",
            image: ServicesNLP,
            lazyImage: ServicesNLPLazy
        },
        {
            title: "Robotics Hardware",
            description: "Our Robotics Hardware services are dedicated to the meticulous design and development of the physical components that constitute robots.",
            content: "Our Robotics Hardware services are dedicated to the meticulous design and development of the physical components that constitute robots. We take pride in creating robotic systems that excel in precision, durability, and adaptability. Whether it's manufacturing automation, medical robotics, or autonomous vehicles, our solutions ensure that the hardware foundation of your robotic systems is robust, reliable, and ready to tackle the challenges of your industry head-on.",
            image: ServicesRH,
            lazyImage: ServicesRHLazy
        },
        {
            title: "Robotics Software",
            description: "In the realm of Robotics Software, we specialize in creating the intelligent software components that serve as the brains behind robots.",
            content: "In the realm of Robotics Software, we specialize in creating the intelligent software components that serve as the brains behind robots. Our software solutions provide robots with the ability to navigate autonomously, make complex decisions, and interact with their environments effectively. With our expertise, your robotic systems can seamlessly integrate into various industries, automating tasks, increasing efficiency, and ultimately transforming your operations.",
            image: ServicesRS,
            lazyImage: ServicesRSLazy

        },
        {
            title: "Embedded Systems",
            description: "Embedded Systems are the heart of many modern devices and machines. Our dedicated team excels in designing and developing embedded systems that power IoT devices.",
            content: "Embedded Systems are the heart of many modern devices and machines. Our dedicated team excels in designing and developing embedded systems that power IoT devices, medical equipment, automotive systems, and more. We prioritize reliability, performance, and connectivity, ensuring that our embedded solutions meet the highest standards of quality and functionality, resulting in technology that is dependable and capable of delivering outstanding performance.",
            image: ServicesES,
            lazyImage: ServicesESLazy

        },
        {
            title: "Internet of Things",
            description: "The Internet of Things (IoT) has revolutionized the way businesses operate, and our IoT services are at the forefront of this revolution.",
            content: "The Internet of Things (IoT) has revolutionized the way businesses operate, and our IoT services are at the forefront of this revolution. We enable organizations to connect and manage a multitude of devices, collect and analyze data, and create intelligent, responsive systems. Whether it's optimizing supply chains, enhancing home automation, or improving asset tracking, our IoT solutions are tailored to empower your business with enhanced efficiency, cost savings, and innovation.",
            image: ServicesIOT,
            lazyImage: ServicesIOTLazy

        },
        {
            title: "PCB Design",
            description: "In PCB design is critical for the success of electronic products, and our PCB Design services ensure that your electronics are designed with the utmost attention to detail.",
            content: "Precision in PCB design is critical for the success of electronic products, and our PCB Design services ensure that your electronics are designed with the utmost care and attention to detail. We specialize in creating PCB layouts that optimize electrical connectivity, signal integrity, and miniaturization. Our PCB designs are tailored to your specific requirements, ensuring that your electronic products meet the highest standards of performance and reliability.",
            image: ServicesPCB,
            lazyImage: ServicesPCBLazy

        },
        {
            title: "Software Development",
            description: "Our Software Development services span a broad spectrum of custom software solutions, including web and mobile applications, desktop software, and backend systems.",
            content: "Our Software Development services span a broad spectrum of custom software solutions, including web and mobile applications, desktop software, and backend systems. We are committed to creating software that aligns perfectly with your business objectives, enhancing productivity, user experience, and overall operational efficiency. With our expertise in software development, we can help your organization streamline processes, engage users, and stay at the forefront of technological innovation.",
            image: ServicesSD,
            lazyImage: ServicesSDLazy

        },
    ],

    CommonText: {
        content: "Ready to embark on a journey of innovation and transformation? Contact us today to explore how our specialized product development services can turn your ideas into reality, drive your business forward, and create a brighter technological future. Together, we can achieve remarkable outcomes that will set your organization apart in the competitive landscape."
    },

    MoreInfo: {
        title: "Exceeding expectations, Zebu symbolizes accomplishment, excellence, reliability, and distinctiveness in delivering solutions and services to its clients.",
        carousel: [
            {
                // image:,
                content: "Every day, our dedicated efforts are geared towards surpassing client expectations. We ensure timely delivery and offer optimal solutions tailored to each project's needs. Guided by unwavering commitment, our teams meticulously analyze and creatively approach every project, driven by the pursuit of the finest solutions."
            },
            {
                // image:,
                content: "Every day, our dedicated efforts are geared towards surpassing client expectations. We ensure timely delivery and offer optimal solutions tailored to each project's needs. Guided by unwavering commitment, our teams meticulously analyze and creatively approach every project, driven by the pursuit of the finest solutions."
            },
            {
                // image:,
                content: "Every day, our dedicated efforts are geared towards surpassing client expectations. We ensure timely delivery and offer optimal solutions tailored to each project's needs. Guided by unwavering commitment, our teams meticulously analyze and creatively approach every project, driven by the pursuit of the finest solutions."
            },
        ]
    },

    Customers: [
        CustomersImage1,
        CustomersImage3,
        CustomersImage2,
        CustomersImage6,
        CustomersImage5,
        CustomersImage4,
    ],

    lazyCustomerImages: [
        CustomersLazyImage1,
        CustomersLazyImage3,
        CustomersLazyImage2,
        CustomersLazyImage6,
        CustomersLazyImage5,
        CustomersLazyImage4,
    ],

    Carousels: [
        CarouselsImage1,
        CarouselsImage2,
        CarouselsImage3
    ],

    lazyCarouselsImages:[
        CarouselsLazyImage1,
        CarouselsLazyImage2,
        CarouselsLazyImage3,
    ],

    GetInTouch: {
        title: [
            "We would love to hear from you,",
            "and our team is ready to help build your product."
        ]
    }
}

export const CareersContent = {
    title: "Join us in our mission to build intelligent systems",
    SubTitle: "Explore exciting career opportunities and become part of our innovative and dynamic team. Discover your potential and grow with us.",
    content: [
        "At our company, we believe in a hustling culture where we work hard to achieve our goals and make a meaningful impact. We value productivity and recognize that it takes dedication and effort to succeed. We are committed to creating a supportive work environment where everyone can thrive and achieve their full potential.",
        "We encourage creativity, innovation, and risk-taking, and we believe that everyone's ideas and contributions are important. We are dedicated to delivering results and are always looking for ways to improve and exceed expectations.",
        "We understand that a hustling culture can be challenging, but we are committed to supporting the personal and professional growth of our employees. We offer opportunities for learning, development, and advancement and provide the resources and support necessary to achieve success.",
        "Overall, our company culture is one of hard work, determination, and results. We are passionate about what we do and are committed to making a positive impact on the world through our work."
    ],
    linkedIn: [
        "Love what we do and who we are?",
        "Want to be part our team?",
        "Apply on LinkedIn"
    ]
}

export const ContactContent = {
    title: "Drop us a line, we would love to hear from you",
    SubTitle: "Reach out to us for unparalleled expertise in AI-driven solutions. Experience innovation that transforms challenges into opportunities, tailored to your specific needs.",
    address: "6th Floor, DGS Prime, Siddhi Vinayak Nagar, Madhapur, Hyderabad, Telangana 500081",
    image: ContactImage,
    lazyImage: ContactLazyImage,
}