import React from 'react'
import { ProductsContent } from '../Data/Data'
import { useNavigate } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Products() {
    const navigate = useNavigate();
    const navigateToContact = () => {
        navigate('/contact')
    }
    return (
        <div className='font-Poppins'>
            <LazyLoadImage src={ProductsContent.mainPage.image}
                placeholderSrc={ProductsContent.mainPage.lazyImage}
                effect="blur" className='object-contain w-full h-full lg:h-[calc(100vh-90px)] lg:w-screen'
            />
            <div className='ms-4 me-4 md:ms-7 md:me-7 lg:ms-16 lg:me-16 mt-10'>

                {/* Tethered Drone Systems */}
                <div className='mt-10 lg:mt-20 lg:grid lg:grid-cols-2 lg:items-center'>
                    <div className='sm:pb-5 md:pb-5 text-center sm:text-center md:text-center lg:text-justify xl:text-justify 2xl:text-justify text-sm sm:text-sm md:text-md lg:text-lg'>
                        <p className='font-bold text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl pb-2 lg:pb-0'>{ProductsContent.tetheredDroneSystems.title}</p>
                        <p className='me-4 lg:mt-5'>{ProductsContent.tetheredDroneSystems.content}</p>
                    </div>
                    <div className='flex justify-center lg:justify-end xl:justify-center pt-5 lg:pt-0 '>
                        <LazyLoadImage src={ProductsContent.tetheredDroneSystems.image}
                            placeholderSrc={ProductsContent.tetheredDroneSystems.lazyImage}
                            effect="blur" className='object-contain w-full h-full max-w-[200px] sm:max-w-[300px] md:max-w-[400px] lg:w-max-[500px] rounded-lg'
                        />
                    </div>
                </div>

                {/* Drone Products */}
                <div className='mt-0 lg:mt-20 lg:grid lg:grid-cols-2'>

                    <div className='pt-16 block sm:block md:block lg:hidden text-center sm:text-center md:text-center lg:text-justify xl:text-justify 2xl:text-justify text-sm sm:text-sm md:text-md lg:text-lg'>
                        <p className='font-bold text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl'>{ProductsContent.droneProducts.title}</p>
                        <p className='pt-5 pb-5'>{ProductsContent.droneProducts.content}</p>
                    </div>

                    <div className='flex justify-center lg:justify-end xl:justify-center pt-5 lg:pt-0 '>
                        <LazyLoadImage src={ProductsContent.droneProducts.image}
                            placeholderSrc={ProductsContent.droneProducts.lazyImage}
                            effect="blur" className='object-contain w-full h-full max-w-[200px] sm:max-w-[300px] md:max-w-[400px] lg:w-max-[500px] rounded-lg'
                        />
                    </div>

                    <div className='flex items-center'>
                        <div className='ms-4 hidden sm:hidden md:hidden lg:block text-sm sm:text-sm md:text-md lg:text-lg'>
                            <p className='font-bold text-center sm:text-center md:text-center lg:text-start text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl'>{ProductsContent.droneProducts.title}</p>
                            <p className='mt-5 text-justify sm:text-center md:text-center lg:text-justify'>{ProductsContent.droneProducts.content}</p>
                        </div>
                    </div>

                </div>

                {/* Counter-Drone Systems */}
                <div className='mt-10 lg:mt-20 lg:grid lg:grid-cols-2 lg:items-center'>
                    <div className='sm:pb-5 md:pb-5 text-center sm:text-center md:text-center lg:text-justify xl:text-justify 2xl:text-justify text-sm sm:text-sm md:text-md lg:text-lg'>
                        <p className='font-bold text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl pb-2 lg:pb-0'>{ProductsContent.counterDroneSystems.title}</p>
                        <p className='me-4 lg:mt-5'>{ProductsContent.counterDroneSystems.content}</p>
                    </div>
                    <div className='flex justify-center lg:justify-end xl:justify-center pt-5 lg:pt-0 '>
                        <LazyLoadImage src={ProductsContent.counterDroneSystems.image}
                            placeholderSrc={ProductsContent.counterDroneSystems.lazyImage}
                            effect="blur" className='object-contain w-full h-full max-w-[200px] sm:max-w-[300px] md:max-w-[400px] lg:w-max-[500px] rounded-lg'
                        />
                    </div>
                </div>

            </div>
            <div className='lg:flex lg:flex-row bg-white my-10'>
                <div className='flex justify-center'>
                    <LazyLoadImage src={ProductsContent.mainPage.image}
                        placeholderSrc={ProductsContent.mainPage.lazyImage}
                        effect="blur" className='pt-5 lg:pt-0 object-contain rounded-lg w-96'
                    />
                </div>
                <div className='flex justify-center items-center w-full p-5 bg-[#F6F6F6] text-[#8A8A8A]'>
                    <div className='text-center'>
                        <p>{ProductsContent.mainPage.content1}</p>
                        <br />
                        <p>{ProductsContent.mainPage.content2}</p>
                        <div className="mt-5">
                            <button type="submit" className="rounded-full bg-black mt-0 px-7 py-2 text-sm font-semibold text-white" onClick={navigateToContact}>
                                Get In Touch
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ms-4 me-4 md:ms-7 md:me-7 lg:ms-16 lg:me-16 mt-10'>

                {/* Autonomous Wheeled Robots */}
                <div className='lg:grid lg:grid-cols-2 lg:items-center'>
                    <div className='sm:pb-5 md:pb-5 text-center sm:text-center md:text-center lg:text-justify xl:text-justify 2xl:text-justify text-sm sm:text-sm md:text-md lg:text-lg'>
                        <p className='font-bold text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl pb-2 lg:pb-0'>{ProductsContent.autonomousWheeledRobots.title}</p>
                        <p className='me-4 lg:mt-5'>{ProductsContent.autonomousWheeledRobots.content}</p>
                    </div>
                    <div className='flex justify-center lg:justify-end xl:justify-center pt-5 lg:pt-0 '>
                        <LazyLoadImage src={ProductsContent.autonomousWheeledRobots.image}
                            placeholderSrc={ProductsContent.autonomousWheeledRobots.lazyImage}
                            effect="blur" className='object-contain w-full h-full max-w-[200px] sm:max-w-[300px] md:max-w-[400px] lg:w-max-[500px] rounded-lg'
                        />
                    </div>
                </div>

                {/* Autonomous Legged Robots */}
                <div className='mt-0 lg:mt-20 lg:grid lg:grid-cols-2'>

                    <div className='pt-16 block sm:block md:block lg:hidden text-center sm:text-center md:text-center lg:text-justify xl:text-justify 2xl:text-justify text-sm sm:text-sm md:text-md lg:text-lg'>
                        <p className='font-bold text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl'>{ProductsContent.autonomousLeggedRobots.title}</p>
                        <p className='pt-5 pb-5'>{ProductsContent.autonomousLeggedRobots.content}</p>
                    </div>

                    <div className='flex justify-center lg:justify-end xl:justify-center pt-5 lg:pt-0 '>
                        <LazyLoadImage src={ProductsContent.autonomousLeggedRobots.image}
                            placeholderSrc={ProductsContent.autonomousLeggedRobots.lazyImage}
                            effect="blur" className='object-contain w-full h-full max-w-[200px] sm:max-w-[300px] md:max-w-[400px] lg:w-max-[500px] rounded-lg'
                        />
                    </div>

                    <div className='flex items-center'>
                        <div className='px-4 hidden sm:hidden md:hidden lg:block text-sm sm:text-sm md:text-md lg:text-lg'>
                            <p className='font-bold text-center sm:text-center md:text-center lg:text-start text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl'>{ProductsContent.autonomousLeggedRobots.title}</p>
                            <p className='mt-5 text-justify sm:text-center md:text-center lg:text-justify'>{ProductsContent.autonomousLeggedRobots.content}</p>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Products