import React, { useRef, useState } from 'react'

import { ContactContent } from '../Data/Data'
import { BsLinkedin, BsGlobe } from 'react-icons/bs'
import emailjs from '@emailjs/browser';

import Swal from 'sweetalert2'
import ReCAPTCHA from 'react-google-recaptcha';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


function Contact() {


    const captchaRef = useRef(null);
    const form = useRef();
    const [validate, setValidate] = useState(true)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const resetForm = () => {
        setName("")
        setEmail("")
        setMessage("")
    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const sendEmail = async (e) => {
        
        e.preventDefault();
        let token = captchaRef.current.getValue();
        let reCaptchaResponse = window.grecaptcha.getResponse();
        if (token && reCaptchaResponse.length !== 0) {
            setValidate(true)
            window.grecaptcha.reset();
            resetForm();
            emailjs.sendForm('service_bfmfqf2', 'template_6tkxb5n', form.current, '_jVP5o-pjANirvc7k')
                .then((result) => {
                    Toast.fire({
                        icon: 'success',
                        title: 'Contact Details Sent'
                    })
                }, (error) => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Failed to send Details'
                    })

                });
            setValidate(false);
        }
    };

    return (
        <div className='font-Poppins min-h-screen'>
            <div className='bg-white pb-10'>
                <div className='mx-5 lg:mx-80 text-center'>
                    <p className='pt-10 sm:text-xl md:text-2xl lg:text-3xl font-bold'>{ContactContent.title}</p>
                    <p className='pt-2 text-[#8A8A8A] text-sm sm:text-sm md:text-md lg:text-lg'>{ContactContent.SubTitle}</p>
                </div>
            </div>
            <div className='lg:grid lg:grid-cols-2'>
                <form onSubmit={sendEmail} ref={form} className='lg:pt-20 mx-5 sm:mx-10 md:mx-15 lg:mx-20'>

                    <div className='font-bold p-2 pt-5 lg:pt-0 sm:text-xl md:text-2xl lg:text-3xl text-center lg:text-start'>Contact Us</div>

                    <div className='lg:grid lg:grid-cols-1 lg:gap-4 text-center font-bold'>

                        <div className="mt-5 lg:mt-10">
                            <div className="mt-2">
                                <input value={name} onChange={(e) => setName(e.target.value)} required placeholder='Name' id="name" name="user_name" type="text" autoComplete="name" className="p-3 block text-sm md:text-lg lg:text-xl h-12 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-black sm:leading-6" />
                            </div>
                        </div>

                        <div className="">
                            <div className="mt-10">
                                <input value={email} onChange={(e) => setEmail(e.target.value)} required placeholder='Email' id="email" name="user_email" type="email" autoComplete="email" className="p-3 block text-sm md:text-lg lg:text-xl w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:leading-6" />
                            </div>
                        </div>

                        <div className="">
                            <div className="mt-10">
                                <textarea value={message} onChange={(e) => setMessage(e.target.value)} id="message" placeholder='Message' name="message" rows="8" className="sm:leading-6 p-3 block w-full text-sm md:text-lg lg:text-xl text-black bg-white border-0 focus:ring-0" />
                            </div>
                        </div>

                        <div className='mt-2 flex justify-center '>
                            <ReCAPTCHA className={validate === false ? `border-2 border-red-500` : ``}
                                sitekey="6Le6tmAoAAAAALeyfPPKLT9BI5-X4kS_R8k2Wavh"
                                ref={captchaRef}
                            />
                        </div>

                        <div className="mt-2 pb-5 flex justify-center gap-10">
                            <button type="submit" className="rounded-full bg-black px-7 py-2 text-sm font-semibold text-white">
                                Submit
                            </button>
                        </div>
                    </div>


                </form>
                <div className='hidden lg:block'>
                    <div className='lg:flex lg:justify-center lg:items-center mt-32'>
                        <LazyLoadImage src={ContactContent.image}
                            placeholderSrc={ContactContent.lazyImage}
                            effect="blur" className='object-contain'
                        />
                        {/* <img className='object-contain' src={ContactContent.image} alt="" /> */}
                    </div>
                </div>
            </div>
            <div className='text-sm sm:text-sm md:text-md lg:text-lg text-center mx-2'>
                <span className='font-bold'>Address:</span> {ContactContent.address}
            </div>
            <div className='pt-5 flex justify-center gap-10 text-sm sm:text-sm md:text-md lg:text-lg'>
                <a href='https://www.linkedin.com/company/zebu-intelligent-systems/mycompany/' target='_blank' rel='noreferrer' className='flex justify-center gap-1'>
                    <BsLinkedin className='mt-1 cursor-pointer ' />
                    <div className='cursor-pointer'>LinkedIn</div>
                </a>
                <a href='https://www.zebu.ai/' target='_blank' rel='noreferrer' className='flex justify-center gap-1'>
                    <BsGlobe className='mt-1 cursor-pointer ' />
                    <div className='cursor-pointer'>Website</div>
                </a>
            </div>
        </div>
    )
}

export default Contact