import React from 'react'
import { AboutUsImages } from '../Data/Data'
import { BiLinkExternal } from 'react-icons/bi'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
function AboutUs() {
  return (
    <div className='font-Poppins text-justify'>
      <LazyLoadImage src={AboutUsImages.images[0].image}
        placeholderSrc={AboutUsImages.lazyImages[0].image}
        effect="blur" className='object-contain w-full h-full lg:h-[calc(100vh-90px)] lg:w-screen'
      />
      <div className='ms-4 me-4 md:ms-7 md:me-7 lg:ms-16 lg:me-16 mt-10'>

        <div className='lg:grid lg:grid-cols-2 lg:items-center'>
          <div className='sm:pb-5 md:pb-5 text-center sm:text-center md:text-center lg:text-justify xl:text-justify 2xl:text-justify text-sm sm:text-sm md:text-md lg:text-lg xl:text-lg 2xl:text-lg'>
            <p className='font-bold text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl pb-2 lg:pb-0'>Who we are</p>
            <p className='me-4 lg:mt-5'>Our team comprises researchers and engineers from top institutions from India and abroad, we are all like-minded people that have a peculiar interest in research and innovation especially in the domains of Artificial Intelligence, mobile robotics and UAVs. We are a team of 20+ people and we are a perfect blend of researchers and Engineers, cumulatively, excluding academic partners, we have authored 20+ publications in leading journals/conferences around the world. </p>
            <p className='me-4 lg:mt-5 mt-5 pb-5'>Led by <a className='font-bold' href='https://sbanisetty.com/' target='_blank' rel="noreferrer">Santosh<BiLinkExternal className='inline mx-1' /></a>, a visionary founder with a PhD in Intelligent Systems and Robotics, master in control systems and postdoctoral experience from a prominent US institutions. Enriched by roles at Tesla and a successful IoT startup exit. Santosh bring onto the table his extensive research and entrepreneurial acumen.</p>
          </div>
          <div className='flex justify-center lg:justify-end xl:justify-center pt-5 lg:pt-0'>
            <LazyLoadImage src={AboutUsImages.images[1].image}
              placeholderSrc={AboutUsImages.lazyImages[1].image}
              effect="blur" className='object-contain max-w-[200px] sm:max-w-[300px] md:max-w-[400px] lg:w-max-[500px] rounded-lg'
            />
          </div>
        </div>

        <div className='mt-0 lg:mt-20 lg:grid lg:grid-cols-2'>

          <div className='pt-16 block sm:block md:block lg:hidden text-center sm:text-center md:text-center lg:text-justify xl:text-justify 2xl:text-justify text-sm sm:text-sm md:text-md lg:text-lg xl:text-lg 2xl:text-lg'>
            <p className='font-bold text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl'>What we do</p>
            <p className='pt-5 pb-5'>Zebu Intelligent Systems specializes in pioneering AI and Robotics solutions for Industry 4.0 challenges. With a focus on defense and transportation automation, we leverage cutting-edge technology to develop advanced products and provide expert services that redefine possibilities and enhance operational efficiency. </p>
          </div>

          <div className='flex justify-center lg:justify-end xl:justify-center pt-5 lg:pt-0'>
            <LazyLoadImage src={AboutUsImages.images[2].image}
              placeholderSrc={AboutUsImages.lazyImages[2].image}
              effect="blur" className='object-contain max-w-[200px] sm:max-w-[300px] md:max-w-[400px] lg:w-max-[500px] rounded-lg'
            />
          </div>

          <div className='flex items-center'>
            <div className='ms-4 hidden sm:hidden md:hidden lg:block text-sm sm:text-sm md:text-md lg:text-lg xl:text-lg 2xl:text-lg'>
              <p className='font-bold text-center sm:text-center md:text-center lg:text-start text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl'>What we do</p>
              <p className='mt-5 text-justify sm:text-center md:text-center lg:text-justify'>Zebu Intelligent Systems specializes in pioneering AI and Robotics solutions for Industry 4.0 challenges. With a focus on defense and transportation automation, we leverage cutting-edge technology to develop advanced products and provide expert services that redefine possibilities and enhance operational efficiency. </p>
            </div>
          </div>

        </div>

        <div className='pb-16 lg:mt-20 lg:grid lg:grid-cols-2'>
          <div className='flex items-center'>
            <div className='pt-5 lg:pt-0 text-center sm:text-center md:text-center lg:text-justify xl:text-justify 2xl:text-justify mx-5 text-md sm:text-md md:text-lg lg:text-lg'>
              <p className='font-bold pt-5 text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl'>Our mission and vision</p>
              <div className='text-sm sm:text-sm md:text-md lg:text-lg xl:text-lg 2xl:text-lg'>
                <p className='mt-5'>Empowering the Future through AI and Robotics Excellence.</p>
                <p className='mt-5'>Our mission is to drive the advancement of Industry 4.0 solutions by harnessing the power of AI and Robotics. We are dedicated to addressing complex challenges through our cutting-edge services and innovative products. By pushing the boundaries of technology, we aim to redefine possibilities, enhance operational efficiency, and contribute to a safer and more automated world.</p>
              </div>
            </div>
          </div>
          <div className='flex justify-center lg:justify-end xl:justify-center pt-5 lg:pt-0'>
            <LazyLoadImage src={AboutUsImages.images[3].image}
              placeholderSrc={AboutUsImages.lazyImages[3].image}
              effect="blur" className='object-contain max-w-[200px] sm:max-w-[300px] md:max-w-[400px] lg:w-max-[500px] rounded-lg'
            />
          </div>
        </div>

      </div>
      <div className='mt-8 bg-white text-center font-extrabold p-5'>
        <div className='text-xl sm:text-xl md:text-2xl lg:text-3xl'>Ecosystem Partners</div>
        <div className='mt-8 grid grid-cols-3 gap-5 md:grid md:grid-cols-3 md:gap-5 text-center lg:flex lg:justify-center lg:items-center lg:gap-5'>
          {
            AboutUsImages.ecoSystem.map((image, key) =>
              <div key={key} className='md:w-26 md:flex md:justify-center md:items-center'>
                <LazyLoadImage src={image}
                  placeholderSrc={AboutUsImages.ecoSystemLazy[key]}
                  effect="blur" className='object-fill'
                />
              </div>
            )
          }
        </div>

      </div>
    </div>
  )
}

export default AboutUs