import React from 'react'
import { HomePageContent } from '../Data/Data'

function Home() {
    return (
        <div className='h-full flex flex-col justify-center items-center text-center'>
            <div className='mt-auto self-center'>
                <div className='font-title text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl'>
                    {HomePageContent.title}
                </div>
                <span className="py-5 flex justify-center">
                    <div className="border-t-2 lg:border-2 w-24 sm:w-24 md:w-48 lg:w-96 border-black "></div>
                </span>
                <div className='font-Poppins italic text-sm sm:text-sm md:text-sm lg:text-md xl:text-lg 2xl:text-xl'>
                    {HomePageContent.tagLine}
                </div>
            </div>
            <div className='mt-auto'>
                <div className='flex justify-center font-Poppins font-extrabold'>
                    <div className="mt-3 mx-3 border-t-2 w-32 border-black "></div>
                    <div className='text-sm sm:text-sm md:text-sm lg:text-md xl:text-lg 2xl:text-xl text-justify'>{HomePageContent.subtagLine}</div>
                    <div className="mt-3 mx-3 border-t-2 w-32 border-black "></div>
                </div>
            </div>
        </div>
    )
}

export default Home