import React from "react";
import { Route, Routes } from "react-router-dom";

import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import AboutUs from "./Components/AboutUs";
import Products from "./Components/Products";
// import Services from './Components/Services' Removed on 26/09/2024
import Careers from "./Components/Careers";
// import Blog from './Components/Blog' Under Development
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Design from "./Components/Design";
import PageNotFound from "./Components/PageNotFound";
function App() {
  return (
    <div className="">
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <div className="h-[calc(100vh-144px)]">
              <Design />
              <Home />
            </div>
          }
        />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/products" element={<Products />} />
        {/* <Route path='/services' element={<Services />} /> */}
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
