import React from "react";

const PageNotFound = () => {
  return (
    <div className="flex justify-center items-center font-bold text-xl font-Poppins h-[calc(100vh-144px)]">
      Something is wrong!
    </div>
  );
};

export default PageNotFound;
