import React from 'react'

import { CareersContent } from '../Data/Data'

function Careers() {
    return (
        <div className='font-Poppins h-[calc(100vh-144px)]'>
            <div className='bg-white pb-10'>
                <div className='mx-5 lg:mx-80 text-center'>
                    <p className='pt-10 sm:text-xl md:text-2xl lg:text-3xl font-bold'>{CareersContent.title}</p>
                    <p className='pt-2 text-[#8A8A8A] text-sm sm:text-sm md:text-md lg:text-lg'>{CareersContent.SubTitle}</p>
                </div>
            </div>
            <div className='lg:pt-10 mx-4 p-3 lg:p-10 text-justify lg:text-start text-sm sm:text-sm md:text-md lg:text-lg xl:text-lg 2xl:text-lg font-light'>
                {
                    CareersContent.content.map((para, key) =>
                        <div key={key} className='p-2'>
                            {para}

                        </div>
                    )
                }
            </div>
            <div className='pt-10 text-center bg-white'>
                <div className='sm:text-xl md:text-2xl lg:text-3xl font-bold'>
                    {
                        CareersContent.linkedIn.map((para, key) =>
                            <div key={key} className='p-1'>
                                {para}
                            </div>
                        )
                    }
                </div>
                <div className='inline-block cursor-pointer my-5 rounded-full bg-black px-5 py-2 mt-5 text-white '>
                    <a className='' target='_blank' rel="noreferrer" href="https://www.linkedin.com/company/zebu-intelligent-systems/mycompany/">LinkedIn</a>
                </div>
            </div>
        </div>
    )
}

export default Careers